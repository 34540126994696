import { getModifier } from "../db";
import { AttackType } from "../model/attack_type";
import { Boss, isBoss } from "../model/boss";
import { DamageType } from "../model/damage_type";
import { DieRoll } from "../model/dice";
import { BaseEnemy, Enemy, isEnemy } from "../model/enemy";
import { EnemyState, hasStatus } from "../model/game";
import { Modifier } from "../model/modifier";
import { StatusEffect } from "../model/status_effect";
import { Targeting } from "../model/targeting";

export class MinionObj {
  get name() {
    return this.enemy.name;
  }
  get id() {
    return this.state.id;
  }
  get letter() {
    return this.state.letter;
  }
  get currentHp() {
    return this.state.currentHp;
  }
  get cooldowns() {
    return this.state.cooldowns;
  }
  get armor() {
    return this.enemy.armor;
  }
  get magicArmor() {
    return this.enemy.magicArmor;
  }
  get text() {
    return isEnemy(this.enemy) ? this.enemy.text : ["[BOSS]"];
  }
  get damageType() {
    return isEnemy(this.enemy) ? this.enemy.damageType : DamageType.PHYSICAL;
  }
  get range() {
    return isEnemy(this.enemy) ? this.enemy.range : undefined;
  }
  get attackRoll(): DieRoll {
    return isEnemy(this.enemy) ? this.enemy.attackRoll : { color: "W", num: 1 };
  }
  get level() {
    return this.enemy.level;
  }
  get targeting() {
    return isEnemy(this.enemy) ? this.enemy.targeting : Targeting.CLOSEST;
  }
  get move() {
    return isEnemy(this.enemy) ? this.enemy.move : 0;
  }
  get attackType() {
    return isEnemy(this.enemy) ? this.enemy.attackType : AttackType.SPECIAL;
  }
  get ap() {
    return isBoss(this.enemy) ? this.enemy.ap : undefined;
  }
  readonly isSundered: boolean;
  readonly isBlinded: boolean;
  readonly isDominated: boolean;
  readonly isFrozen: boolean;
  readonly isKnocked: boolean;
  readonly isStunned: boolean;
  readonly isShocked: boolean;
  readonly isChicken: boolean;
  readonly isPoisoned: boolean;
  readonly isBleeding: boolean;
  readonly isExposed: boolean;
  readonly isBurning: boolean;

  readonly hpLossPerTurn: number;

  readonly maxHp: number;

  readonly isBoss: boolean;

  readonly modifiers: Modifier[];

  constructor(
    private readonly enemy: Enemy | Boss,
    private readonly state: EnemyState
  ) {
    this.isSundered = hasStatus(this.state, StatusEffect.SUNDERED);
    this.isBlinded = hasStatus(this.state, StatusEffect.BLINDED);
    this.isDominated = hasStatus(this.state, StatusEffect.DOMINATED);
    this.isFrozen = hasStatus(this.state, StatusEffect.FROZEN);
    this.isKnocked = hasStatus(this.state, StatusEffect.KNOCKDOWN);
    this.isStunned = hasStatus(this.state, StatusEffect.STUNNED);
    this.isShocked = hasStatus(this.state, StatusEffect.SHOCKED);
    this.isChicken = hasStatus(this.state, StatusEffect.CHICKEN);
    this.isPoisoned = hasStatus(this.state, StatusEffect.POISON);
    this.isBleeding = hasStatus(this.state, StatusEffect.BLEEDING);
    this.isExposed = hasStatus(this.state, StatusEffect.EXPOSED);
    this.isBurning = hasStatus(this.state, StatusEffect.BURNING);

    let hpLossPerTurn = 0;
    if (this.isPoisoned) hpLossPerTurn += 2;
    if (this.isBurning) hpLossPerTurn += 2;
    if (this.isBleeding) hpLossPerTurn += 1;
    this.hpLossPerTurn = hpLossPerTurn;

    this.modifiers = (this.state.modifiers ?? []).map(getModifier);
    const maxHp = isBoss(this.enemy)
      ? this.state.maxHp
      : (this.enemy as Enemy).hp;
    this.maxHp = maxHp + this.modifiers.reduce((a, c) => a + (c.hp ?? 0), 0);

    this.isBoss = isBoss(this.enemy);
  }
}

import { FlatList, View, StyleSheet } from "react-native";
import { Button, Card, SegmentedButtons, Text } from "react-native-paper";
import { getAllModifiers } from "../db";
import { Modifier } from "../model/modifier";
import { useState } from "react";
import { globalStyles } from "./global_styles";
import { icon } from "./GameIcon";
import { ICONS } from "../bundles/icons";
import RichText from "./RichText";
import { COLORS } from "./colors";
import { TEXT } from "./text";
import { LIGHT_FONT } from "../bundles/fonts";
import { useSelector, useStore } from "react-redux";
import { selectUsedModifiers } from "../store/selectors";

type Filter = "all" | "unused";
interface Props {
  onSelect?: (modifier: Modifier) => void;
}

export default function ModifierPicker(props: Props): JSX.Element {
  let availableMods = getAllModifiers();
  const [filter, setFilter] = useState<Filter>("all");
  const [randomCard, setRandomCard] = useState<string | undefined>(undefined);
  const used = useSelector(selectUsedModifiers);
  if (filter === "unused") {
    const usedSet = new Set(used);
    availableMods = availableMods.filter((m) => !usedSet.has(m.id));
  }

  let mods = availableMods;
  if (randomCard) {
    mods = [availableMods.find((m) => m.id === randomCard)];
  }

  function chooseRandomCard(): void {
    const i = Math.floor(Math.random() * availableMods.length);
    setRandomCard(availableMods[i].id);
  }

  return (
    <View style={styles.container}>
      <View style={styles.controls}>
        <SegmentedButtons
          style={{ flex: 1 }}
          buttons={[
            { value: "all", label: "Show all" },
            { value: "unused", label: "Unused only" },
          ]}
          value={filter}
          onValueChange={(v) => {
            setRandomCard(undefined);
            setFilter(v);
          }}
        />
        <View style={{ flex: 4 }} />
        <Button onPress={chooseRandomCard}>Random</Button>
      </View>
      <View style={styles.modList}>
        {mods.map((mod, i) => (
          <ModItem onPress={props.onSelect} key={mod.id} mod={mod} />
        ))}
      </View>
    </View>
  );
}

interface ModItemProps {
  mod: Modifier;
  onPress?: (modifier: Modifier) => void;
}

function ModItem(props: ModItemProps): JSX.Element {
  const { mod, onPress } = props;

  return (
    <Card
      onPress={() => onPress && onPress(mod)}
      mode="outlined"
      style={styles.card}
    >
      <Card.Title titleStyle={styles.heading} title={mod.name} />
      <Card.Content>
        <View style={styles.bonuses}>
          {mod.hp ? <BonusItem icon={ICONS.hp} value={mod.hp} /> : null}
          {mod.armor ? (
            <BonusItem icon={ICONS.physical_armor} value={mod.armor} />
          ) : null}
          {mod.magicArmor ? (
            <BonusItem icon={ICONS.magic_armor} value={mod.magicArmor} />
          ) : null}
        </View>
        <RichText
          text={mod.text}
          style={TEXT.richText}
          headingStyle={TEXT.heading}
        />
      </Card.Content>
    </Card>
  );
}

interface BonusItemProps {
  icon: any;
  value: number;
}

function BonusItem(props: BonusItemProps): JSX.Element {
  return (
    <View style={styles.bonusItem}>
      <Text style={styles.bonusItemText}>+{props.value}</Text>
      {icon(props.icon, styles.bonusIcon)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.white,
  },

  heading: {
    fontFamily: LIGHT_FONT,
    fontSize: 18,
    color: COLORS.gold,
  },

  card: {
    backgroundColor: COLORS.darkRed,
    width: 275,
    height: 150,
  },

  controls: {
    flexDirection: "row",
    padding: 8,
  },

  modList: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 3,
    justifyContent: "center",
  },

  bonuses: {
    flexDirection: "row",
    gap: 5,
    justifyContent: "center",
  },

  bonusItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 3,
  },

  bonusItemText: {
    color: COLORS.white,
    fontFamily: LIGHT_FONT,
    fontSize: 20,
  },

  bonusIcon: {
    width: 18,
  },
});

import { View, StyleSheet, StyleProp, ViewStyle, FlatList } from "react-native";
import { EnemyState } from "../model/game";
import EnemyContainer from "./EnemyContainer";
import { Initiative } from "../model/initiative";
import EnemySelector from "./EnemySelector";
import { FAB } from "react-native-paper";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../store/store";

interface Props {
  style?: StyleProp<ViewStyle>;
  showSelectorFab?: boolean;
  enemies: EnemyState[];
  initiative: Initiative;
}

// Want to be 400 high.

export default function EnemyList(props: Props): React.JSX.Element {
  const { enemies, initiative, showSelectorFab } = props;
  const [showSelector, setShowSelector] = useState(false);

  const dispatch = useDispatch();

  return (
    <View style={[props.style, styles.container]}>
      {showSelectorFab ? (
        <EnemySelector
          visible={showSelector}
          onAdd={(id, letter, init, maxHp) => {
            dispatch(actions.addEnemy({ initiative: init, id, letter, maxHp }));
          }}
          onCancel={() => setShowSelector(false)}
        />
      ) : null}
      <FlatList
        horizontal
        style={styles.enemyList}
        data={enemies}
        keyExtractor={(_, index) => `${index}`}
        ItemSeparatorComponent={() => <View style={{ width: 5 }} />}
        renderItem={(info) => {
          return (
            <EnemyContainer
              enemy={info.item}
              index={info.index}
              initiative={initiative}
            />
          );
        }}
      />
      {showSelectorFab ? (
        <FAB
          style={styles.fab}
          icon="plus"
          onPress={() => {
            setShowSelector(true);
          }}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgba(255,255,255,0.2)",
    borderRadius: 5,
    flexDirection: "row",
    gap: 5,
    padding: 5,
  },

  fab: {
    position: "absolute",
    right: 10,
    bottom: 10,
  },

  enemyList: {
    flex: 1,
  },
});

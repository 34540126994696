import { useEffect, useState } from "react";
import { Platform } from "react-native";

/** React hook that detects changes to the full screen state (for web only). */
export function useFullscreen(): boolean {
  if (Platform.OS !== "web") {
    return false;
  }
  const [isFullscreen, setFullscreen] = useState(!!document.fullscreenElement);
  useEffect(() => {
    const handler = () => {
      const isFullNow = !!document.fullscreenElement;
      if (isFullNow !== isFullscreen) {
        setFullscreen(isFullNow);
      }
    };
    document.addEventListener("fullscreenchange", handler);

    return () => {
      document.removeEventListener("fullscreenchange", handler);
    };
  }, [isFullscreen, setFullscreen]);

  return isFullscreen;
}

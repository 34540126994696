import { Enemy } from "./enemy";
import { Modifier } from "./modifier";
import { StatusEffect } from "./status_effect";

export interface Game {
  loading?: boolean;
  time?: number;
  fast: EnemyState[];
  slow: EnemyState[];
  sawReleaseNotes?: string;
}

export interface EnemyState {
  id: string;
  letter: Letter;
  currentHp: number;
  maxHp?: number;
  cooldowns: Cooldown[];
  modifiers?: string[];
}

export interface Cooldown {
  status: StatusEffect;
  turns: number;
}

export const LETTERS = ["A", "B", "C", "D", "E", "F", "G"] as const;
export type Letter = (typeof LETTERS)[number];

/** Returns whether the enemy has the given status. */
export function hasStatus(enemy: EnemyState, status: StatusEffect): boolean {
  return !!enemy.cooldowns.find((c) => c.status === status);
}

/** Returns the max HP for a minion that possibly has modifiers. */
export function minionMaxHp(minion: Enemy, modifiers: Modifier[]): number {
  return minion.hp + modifiers.reduce((a, c) => a + (c.hp ?? 0), 0);
}

import { View, Image, Text, StyleSheet, ImageBackground } from "react-native";
import { STATUS_IMAGES } from "../bundles/status_images";
import { StatusEffect } from "../model/status_effect";
import { BOLD_FONT, FONTS } from "../bundles/fonts";

interface Props {
  status: StatusEffect;
  turns: number;
}

export default function Effect(props: Props): JSX.Element {
  const { status, turns } = props;

  return (
    <ImageBackground
      source={STATUS_IMAGES.get(status)}
      resizeMode="contain"
      style={styles.statusImage}
    >
      <Text style={styles.turns}>{turns}</Text>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  statusImage: {
    width: 30,
    height: 30,

    justifyContent: "flex-end",
    alignItems: "center",
  },

  turns: {
    color: "#fff",
    fontSize: 16,
    fontFamily: BOLD_FONT,
    textShadowColor: "#000",
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 4,
  },
});

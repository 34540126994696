import { useState } from 'react';
import {
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import Markdown from 'react-native-markdown-display';
import { Asset } from 'expo-asset';
import { ActivityIndicator } from 'react-native-paper';

const NOTES = require('../assets/release_notes/release_notes.md');

interface Props {
  style?: StyleProp<ViewStyle>;
}

export default function ReleaseNotes(props: Props): JSX.Element {
  const [notes, setNotes] = useState<string>(undefined);

  if (!notes) {
    loadNotes().then((n) => setNotes(n));
  }

  return (
    <ScrollView style={[props.style, styles.root]}>
      {notes ? (
        <Markdown style={mdStyles} key={0}>
          {notes}
        </Markdown>
      ) : (
        <ActivityIndicator animating={true} color="blue" size={150} />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 30,
    borderRadius: 4,
  },
});

const mdStyles = StyleSheet.create({
  hr: {
    marginTop: 20,
    marginBottom: 20,
  },
});

async function loadNotes(): Promise<string> {
  try {
    let uri = NOTES;
    if (Platform.OS !== 'web') {
      const loaded = await Asset.loadAsync(NOTES);
      uri = loaded[0].localUri;
    }
    const fetchResult = await fetch(uri);
    return fetchResult.text();
  } catch (e) {
    console.error(`Fetching release notes file failed.`, e);
  }
}

export enum AttackType {
  SINGLE,
  AREA,
  SPECIAL,
}

export function attackTypeText(attackType: AttackType): string {
  switch (attackType) {
    case AttackType.SINGLE:
      return "Single";
    case AttackType.AREA:
      return "Area";
    case AttackType.SPECIAL:
      return "Special";
  }
}

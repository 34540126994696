import { LIGHT_FONT } from "../bundles/fonts";

export const TEXT = {
  richText: {
    padding: 6,
    alignItems: "center",
    textAlign: "center",
    fontFamily: LIGHT_FONT,
    fontSize: 16,
    lineHeight: 17,
  },

  heading: { fontFamily: LIGHT_FONT, fontSize: 12 },
} as const;

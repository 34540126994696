export const ENEMY_IMAGES = {
  "ancient_tainted_turtle": require("../assets/images/minions/ancient_tainted_turtle.png"),
  "ancient_tainted_turtle_portrait": require("../assets/images/portraits/ancient_tainted_turtle.png"),
  "artillery_plant": require("../assets/images/minions/artillery_plant.png"),
  "artillery_plant_portrait": require("../assets/images/portraits/artillery_plant.png"),
  "bandit_aerotheurge": require("../assets/images/minions/bandit_aerotheurge.png"),
  "bandit_aerotheurge_portrait": require("../assets/images/portraits/bandit_aerotheurge.png"),
  "bandit_guard": require("../assets/images/minions/bandit_guard.png"),
  "bandit_guard_portrait": require("../assets/images/portraits/bandit_guard.png"),
  "bandit_hydrosophist": require("../assets/images/minions/bandit_hydrosophist.png"),
  "bandit_hydrosophist_portrait": require("../assets/images/portraits/bandit_hydrosophist.png"),
  "charged_frog": require("../assets/images/minions/charged_frog.png"),
  "charged_frog_portrait": require("../assets/images/portraits/charged_frog.png"),
  "cold_crawler_voidwoken": require("../assets/images/minions/cold_crawler_voidwoken.png"),
  "cold_crawler_voidwoken_portrait": require("../assets/images/portraits/cold_crawler_voidwoken.png"),
  "corrosive_frog": require("../assets/images/minions/corrosive_frog.png"),
  "corrosive_frog_portrait": require("../assets/images/portraits/corrosive_frog.png"),
  "crocodile": require("../assets/images/minions/crocodile.png"),
  "crocodile_portrait": require("../assets/images/portraits/crocodile.png"),
  "cryomancer_guardian": require("../assets/images/minions/cryomancer_guardian.png"),
  "cryomancer_guardian_portrait": require("../assets/images/portraits/cryomancer_guardian.png"),
  "darkhans_lackey": require("../assets/images/minions/darkhans_lackey.png"),
  "darkhans_lackey_portrait": require("../assets/images/portraits/darkhans_lackey.png"),
  "fire_slug": require("../assets/images/minions/fire_slug.png"),
  "fire_slug_portrait": require("../assets/images/portraits/fire_slug.png"),
  "giant_spider": require("../assets/images/minions/giant_spider.png"),
  "giant_spider_portrait": require("../assets/images/portraits/giant_spider.png"),
  "griff_veteran": require("../assets/images/minions/griff_veteran.png"),
  "griff_veteran_portrait": require("../assets/images/portraits/griff_veteran.png"),
  "griff": require("../assets/images/minions/griff.png"),
  "griff_portrait": require("../assets/images/portraits/griff.png"),
  "kraken_tentacle": require("../assets/images/minions/kraken_tentacle.png"),
  "kraken_tentacle_portrait": require("../assets/images/portraits/kraken_tentacle.png"),
  "magister_captain": require("../assets/images/minions/magister_captain.png"),
  "magister_captain_portrait": require("../assets/images/portraits/magister_captain.png"),
  "magister_elementalist": require("../assets/images/minions/magister_elementalist.png"),
  "magister_elementalist_portrait": require("../assets/images/portraits/magister_elementalist.png"),
  "magister_guard": require("../assets/images/minions/magister_guard.png"),
  "magister_guard_portrait": require("../assets/images/portraits/magister_guard.png"),
  "magister_houndmaster": require("../assets/images/minions/magister_houndmaster.png"),
  "magister_houndmaster_portrait": require("../assets/images/portraits/magister_houndmaster.png"),
  "magister_ranger": require("../assets/images/minions/magister_ranger.png"),
  "magister_ranger_portrait": require("../assets/images/portraits/magister_ranger.png"),
  "noxious_salamander": require("../assets/images/minions/noxious_salamander.png"),
  "noxious_salamander_portrait": require("../assets/images/portraits/noxious_salamander.png"),
  "radeka_veteran": require("../assets/images/minions/radeka_veteran.png"),
  "radeka_veteran_portrait": require("../assets/images/portraits/radeka_veteran.png"),
  "radeka": require("../assets/images/minions/radeka.png"),
  "radeka_portrait": require("../assets/images/portraits/radeka.png"),
  "ruffian": require("../assets/images/minions/ruffian.png"),
  "ruffian_portrait": require("../assets/images/portraits/ruffian.png"),
  "seeker_knight": require("../assets/images/minions/seeker_knight.png"),
  "seeker_knight_portrait": require("../assets/images/portraits/seeker_knight.png"),
  "shambling_vegetal": require("../assets/images/minions/shambling_vegetal.png"),
  "shambling_vegetal_portrait": require("../assets/images/portraits/shambling_vegetal.png"),
  "silent_monk": require("../assets/images/minions/silent_monk.png"),
  "silent_monk_portrait": require("../assets/images/portraits/silent_monk.png"),
  "snow_warden": require("../assets/images/minions/snow_warden.png"),
  "snow_warden_portrait": require("../assets/images/portraits/snow_warden.png"),
  "source_hound": require("../assets/images/minions/source_hound.png"),
  "source_hound_portrait": require("../assets/images/portraits/source_hound.png"),
  "source_tiger": require("../assets/images/minions/source_tiger.png"),
  "source_tiger_portrait": require("../assets/images/portraits/source_tiger.png"),
  "storm_wing_voidwoken": require("../assets/images/minions/storm_wing_voidwoken.png"),
  "storm_wing_voidwoken_portrait": require("../assets/images/portraits/storm_wing_voidwoken.png"),
  "troll": require("../assets/images/minions/troll.png"),
  "troll_portrait": require("../assets/images/portraits/troll.png"),
  "trompdoy_archer_veteran": require("../assets/images/minions/trompdoy_archer_veteran.png"),
  "trompdoy_archer_veteran_portrait": require("../assets/images/portraits/trompdoy_archer_veteran.png"),
  "trompdoy_archer": require("../assets/images/minions/trompdoy_archer.png"),
  "trompdoy_archer_portrait": require("../assets/images/portraits/trompdoy_archer.png"),
  "trompdoy_mage_veteran": require("../assets/images/minions/trompdoy_mage_veteran.png"),
  "trompdoy_mage_veteran_portrait": require("../assets/images/portraits/trompdoy_mage_veteran.png"),
  "trompdoy_mage": require("../assets/images/minions/trompdoy_mage.png"),
  "trompdoy_mage_portrait": require("../assets/images/portraits/trompdoy_mage.png"),
  "trompdoy_warrior": require("../assets/images/minions/trompdoy_warrior.png"),
  "trompdoy_warrior_portrait": require("../assets/images/portraits/trompdoy_warrior.png"),
  "trompdoy_warrior_veteran": require("../assets/images/minions/trompdoy_warrior_veteran.png"),
  "trompdoy_warrior_veteran_portrait": require("../assets/images/portraits/trompdoy_warrior_veteran.png"),
  "undead_geomancer": require("../assets/images/minions/undead_geomancer.png"),
  "undead_geomancer_portrait": require("../assets/images/portraits/undead_geomancer.png"),
  "undead_pyromancer": require("../assets/images/minions/undead_pyromancer.png"),
  "undead_pyromancer_portrait": require("../assets/images/portraits/undead_pyromancer.png"),
  "undead_ranger": require("../assets/images/minions/undead_ranger.png"),
  "undead_ranger_portrait": require("../assets/images/portraits/undead_ranger.png"),
  "veteran_ancient_tainted_turtle": require("../assets/images/minions/veteran_ancient_tainted_turtle.png"),
  "veteran_ancient_tainted_turtle_portrait": require("../assets/images/portraits/veteran_ancient_tainted_turtle.png"),
  "veteran_artillery_plant": require("../assets/images/minions/veteran_artillery_plant.png"),
  "veteran_artillery_plant_portrait": require("../assets/images/portraits/veteran_artillery_plant.png"),
  "veteran_bandit_aerotheurge": require("../assets/images/minions/veteran_bandit_aerotheurge.png"),
  "veteran_bandit_aerotheurge_portrait": require("../assets/images/portraits/veteran_bandit_aerotheurge.png"),
  "veteran_bandit_guard": require("../assets/images/minions/veteran_bandit_guard.png"),
  "veteran_bandit_guard_portrait": require("../assets/images/portraits/veteran_bandit_guard.png"),
  "veteran_bandit_hydrosophist": require("../assets/images/minions/veteran_bandit_hydrosophist.png"),
  "veteran_bandit_hydrosophist_portrait": require("../assets/images/portraits/veteran_bandit_hydrosophist.png"),
  "veteran_charged_frog": require("../assets/images/minions/veteran_charged_frog.png"),
  "veteran_charged_frog_portrait": require("../assets/images/portraits/veteran_charged_frog.png"),
  "veteran_cold_crawler_voidwoken": require("../assets/images/minions/veteran_cold_crawler_voidwoken.png"),
  "veteran_cold_crawler_voidwoken_portrait": require("../assets/images/portraits/veteran_cold_crawler_voidwoken.png"),
  "veteran_corrosive_frog": require("../assets/images/minions/veteran_corrosive_frog.png"),
  "veteran_corrosive_frog_portrait": require("../assets/images/portraits/veteran_corrosive_frog.png"),
  "veteran_crocodile": require("../assets/images/minions/veteran_crocodile.png"),
  "veteran_crocodile_portrait": require("../assets/images/portraits/veteran_crocodile.png"),
  "veteran_cryomancer_guardian": require("../assets/images/minions/veteran_cryomancer_guardian.png"),
  "veteran_cryomancer_guardian_portrait": require("../assets/images/portraits/veteran_cryomancer_guardian.png"),
  "veteran_darkhans_lackey": require("../assets/images/minions/veteran_darkhans_lackey.png"),
  "veteran_darkhans_lackey_portrait": require("../assets/images/portraits/veteran_darkhans_lackey.png"),
  "veteran_fire_slug": require("../assets/images/minions/veteran_fire_slug.png"),
  "veteran_fire_slug_portrait": require("../assets/images/portraits/veteran_fire_slug.png"),
  "veteran_giant_spider": require("../assets/images/minions/veteran_giant_spider.png"),
  "veteran_giant_spider_portrait": require("../assets/images/portraits/veteran_giant_spider.png"),
  "veteran_kraken_tentacle": require("../assets/images/minions/veteran_kraken_tentacle.png"),
  "veteran_kraken_tentacle_portrait": require("../assets/images/portraits/veteran_kraken_tentacle.png"),
  "veteran_magister_captain": require("../assets/images/minions/veteran_magister_captain.png"),
  "veteran_magister_captain_portrait": require("../assets/images/portraits/veteran_magister_captain.png"),
  "veteran_magister_elementalist": require("../assets/images/minions/veteran_magister_elementalist.png"),
  "veteran_magister_elementalist_portrait": require("../assets/images/portraits/veteran_magister_elementalist.png"),
  "veteran_magister_guard": require("../assets/images/minions/veteran_magister_guard.png"),
  "veteran_magister_guard_portrait": require("../assets/images/portraits/veteran_magister_guard.png"),
  "veteran_magister_houndmaster": require("../assets/images/minions/veteran_magister_houndmaster.png"),
  "veteran_magister_houndmaster_portrait": require("../assets/images/portraits/veteran_magister_houndmaster.png"),
  "veteran_magister_ranger": require("../assets/images/minions/veteran_magister_ranger.png"),
  "veteran_magister_ranger_portrait": require("../assets/images/portraits/veteran_magister_ranger.png"),
  "veteran_noxious_salamander": require("../assets/images/minions/veteran_noxious_salamander.png"),
  "veteran_noxious_salamander_portrait": require("../assets/images/portraits/veteran_noxious_salamander.png"),
  "veteran_ruffian": require("../assets/images/minions/veteran_ruffian.png"),
  "veteran_ruffian_portrait": require("../assets/images/portraits/veteran_ruffian.png"),
  "veteran_seeker_knight": require("../assets/images/minions/veteran_seeker_knight.png"),
  "veteran_seeker_knight_portrait": require("../assets/images/portraits/veteran_seeker_knight.png"),
  "veteran_shambling_vegetal": require("../assets/images/minions/veteran_shambling_vegetal.png"),
  "veteran_shambling_vegetal_portrait": require("../assets/images/portraits/veteran_shambling_vegetal.png"),
  "veteran_silent_monk": require("../assets/images/minions/veteran_silent_monk.png"),
  "veteran_silent_monk_portrait": require("../assets/images/portraits/veteran_silent_monk.png"),
  "veteran_snow_warden": require("../assets/images/minions/veteran_snow_warden.png"),
  "veteran_snow_warden_portrait": require("../assets/images/portraits/veteran_snow_warden.png"),
  "veteran_source_hound": require("../assets/images/minions/veteran_source_hound.png"),
  "veteran_source_hound_portrait": require("../assets/images/portraits/veteran_source_hound.png"),
  "veteran_source_tiger": require("../assets/images/minions/veteran_source_tiger.png"),
  "veteran_source_tiger_portrait": require("../assets/images/portraits/veteran_source_tiger.png"),
  "veteran_storm_wing_voidwoken": require("../assets/images/minions/veteran_storm_wing_voidwoken.png"),
  "veteran_storm_wing_voidwoken_portrait": require("../assets/images/portraits/veteran_storm_wing_voidwoken.png"),
  "veteran_troll": require("../assets/images/minions/veteran_troll.png"),
  "veteran_troll_portrait": require("../assets/images/portraits/veteran_troll.png"),
  "veteran_undead_geomancer": require("../assets/images/minions/veteran_undead_geomancer.png"),
  "veteran_undead_geomancer_portrait": require("../assets/images/portraits/veteran_undead_geomancer.png"),
  "veteran_undead_pyromancer": require("../assets/images/minions/veteran_undead_pyromancer.png"),
  "veteran_undead_pyromancer_portrait": require("../assets/images/portraits/veteran_undead_pyromancer.png"),
  "veteran_undead_ranger": require("../assets/images/minions/veteran_undead_ranger.png"),
  "veteran_undead_ranger_portrait": require("../assets/images/portraits/veteran_undead_ranger.png"),
  "veteran_void_salamander": require("../assets/images/minions/veteran_void_salamander.png"),
  "veteran_void_salamander_portrait": require("../assets/images/portraits/veteran_void_salamander.png"),
  "veteran_voidwoken_deep_dweller": require("../assets/images/minions/veteran_voidwoken_deep_dweller.png"),
  "veteran_voidwoken_deep_dweller_portrait": require("../assets/images/portraits/veteran_voidwoken_deep_dweller.png"),
  "veteran_voidwoken": require("../assets/images/minions/veteran_voidwoken.png"),
  "veteran_voidwoken_portrait": require("../assets/images/portraits/veteran_voidwoken.png"),
  "veteran_watchtower": require("../assets/images/minions/veteran_watchtower.png"),
  "veteran_watchtower_portrait": require("../assets/images/portraits/veteran_watchtower.png"),
  "veteran_white_wolf": require("../assets/images/minions/veteran_white_wolf.png"),
  "veteran_white_wolf_portrait": require("../assets/images/portraits/veteran_white_wolf.png"),
  "veteran_wolf": require("../assets/images/minions/veteran_wolf.png"),
  "veteran_wolf_portrait": require("../assets/images/portraits/veteran_wolf.png"),
  "void_salamander": require("../assets/images/minions/void_salamander.png"),
  "void_salamander_portrait": require("../assets/images/portraits/void_salamander.png"),
  "voidwoken_deep_dweller": require("../assets/images/minions/voidwoken_deep_dweller.png"),
  "voidwoken_deep_dweller_portrait": require("../assets/images/portraits/voidwoken_deep_dweller.png"),
  "voidwoken": require("../assets/images/minions/voidwoken.png"),
  "voidwoken_portrait": require("../assets/images/portraits/voidwoken.png"),
  "watchtower": require("../assets/images/minions/watchtower.png"),
  "watchtower_portrait": require("../assets/images/portraits/watchtower.png"),
  "white_wolf": require("../assets/images/minions/white_wolf.png"),
  "white_wolf_portrait": require("../assets/images/portraits/white_wolf.png"),
  "wolf": require("../assets/images/minions/wolf.png"),
  "wolf_portrait": require("../assets/images/portraits/wolf.png"),
  "floating_eyes": require("../assets/images/minions/floating_eyes.png"),
  "floating_eyes_portrait": require("../assets/images/portraits/floating_eyes.png"),
  "floating_eyes_veteran": require("../assets/images/minions/floating_eyes_veteran.png"),
  "floating_eyes_veteran_portrait": require("../assets/images/portraits/floating_eyes_veteran.png"),
  "human_mite": require("../assets/images/minions/human_mite.png"),
  "human_mite_portrait": require("../assets/images/portraits/human_mite.png"),
  "human_mite_veteran": require("../assets/images/minions/human_mite_veteran.png"),
  "human_mite_veteran_portrait": require("../assets/images/portraits/human_mite_veteran.png"),
  "memory_eater": require("../assets/images/minions/memory_eater.png"),
  "memory_eater_portrait": require("../assets/images/portraits/memory_eater.png"),
  "memory_eater_veteran": require("../assets/images/minions/memory_eater_veteran.png"),
  "memory_eater_veteran_portrait": require("../assets/images/portraits/memory_eater_veteran.png"),
  "primitive_vampyre": require("../assets/images/minions/primitive_vampyre.png"),
  "primitive_vampyre_portrait": require("../assets/images/portraits/primitive_vampyre.png"),
  "primitive_vampyre_veteran": require("../assets/images/minions/primitive_vampyre_veteran.png"),
  "primitive_vampyre_veteran_portrait": require("../assets/images/portraits/primitive_vampyre_veteran.png"),
  "undead_inquisitor": require("../assets/images/minions/undead_inquisitor.png"),
  "undead_inquisitor_portrait": require("../assets/images/portraits/undead_inquisitor.png"),
  "undead_inquisitor_veteran": require("../assets/images/minions/undead_inquisitor_veteran.png"),
  "undead_inquisitor_veteran_portrait": require("../assets/images/portraits/undead_inquisitor_veteran.png"),
  "undead_knight": require("../assets/images/minions/undead_knight.png"),
  "undead_knight_portrait": require("../assets/images/portraits/undead_knight.png"),
  "undead_knight_veteran": require("../assets/images/minions/undead_knight_veteran.png"),
  "undead_knight_veteran_portrait": require("../assets/images/portraits/undead_knight_veteran.png"),
  "voidwoken_soldier": require("../assets/images/minions/voidwoken_soldier.png"),
  "voidwoken_soldier_portrait": require("../assets/images/portraits/voidwoken_soldier.png"),
  "voidwoken_soldier_veteran": require("../assets/images/minions/voidwoken_soldier_veteran.png"),
  "voidwoken_soldier_veteran_portrait": require("../assets/images/portraits/voidwoken_soldier_veteran.png"),
  "demon_grunt": require("../assets/images/minions/demon_grunt.png"),
  "demon_grunt_portrait": require("../assets/images/portraits/demon_grunt.png"),
  "demon_grunt_veteran": require("../assets/images/minions/demon_grunt_veteran.png"),
  "demon_grunt_veteran_portrait": require("../assets/images/portraits/demon_grunt_veteran.png"),
  "demon_hound": require("../assets/images/minions/demon_hound.png"),
  "demon_hound_portrait": require("../assets/images/portraits/demon_hound.png"),
  "demon_hound_veteran": require("../assets/images/minions/demon_hound_veteran.png"),
  "demon_hound_veteran_portrait": require("../assets/images/portraits/demon_hound_veteran.png"),
  "demon_worm": require("../assets/images/minions/demon_worm.png"),
  "demon_worm_portrait": require("../assets/images/portraits/demon_worm.png"),
  "demon_worm_veteran": require("../assets/images/minions/demon_worm_veteran.png"),
  "demon_worm_veteran_portrait": require("../assets/images/portraits/demon_worm_veteran.png"),
  "winged_demon": require("../assets/images/minions/winged_demon.png"),
  "winged_demon_portrait": require("../assets/images/portraits/winged_demon.png"),
  "winged_demon_veteran": require("../assets/images/minions/winged_demon_veteran.png"),
  "winged_demon_veteran_portrait": require("../assets/images/portraits/winged_demon_veteran.png"),
  "evandrus": require("../assets/images/minions/evandrus.png"),
  "evandrus_portrait": require("../assets/images/portraits/evandrus.png"),
  "gheist": require("../assets/images/minions/gheist.png"),
  "gheist_portrait": require("../assets/images/portraits/gheist.png"),
  "ghoul": require("../assets/images/minions/ghoul.png"),
  "ghoul_portrait": require("../assets/images/portraits/ghoul.png"),
  "slane": require("../assets/images/minions/slane.png"),
  "slane_portrait": require("../assets/images/portraits/slane.png"),
  "shambling_oak": require("../assets/images/minions/shambling_oak.png"),
  "shambling_oak_portrait": require("../assets/images/portraits/shambling_oak.png"),
  "darkhan": require("../assets/images/minions/darkhan.png"),
  "darkhan_portrait": require("../assets/images/portraits/darkhan.png"),
  "venomwing": require("../assets/images/minions/venomwing.png"),
  "venomwing_portrait": require("../assets/images/portraits/venomwing.png"),
  "ice_rhino": require("../assets/images/minions/ice_rhino.png"),
  "ice_rhino_portrait": require("../assets/images/portraits/ice_rhino.png"),
  "dallis": require("../assets/images/minions/dallis.png"),
  "dallis_portrait": require("../assets/images/portraits/dallis.png"),
  "braccus_rex": require("../assets/images/minions/braccus_rex.png"),
  "braccus_rex_portrait": require("../assets/images/portraits/braccus_rex.png"),
  "crypt_guardian": require("../assets/images/minions/crypt_guardian.png"),
  "crypt_guardian_portrait": require("../assets/images/portraits/crypt_guardian.png"),
  "betrayer_crypt_guardian": require("../assets/images/minions/betrayer_crypt_guardian.png"),
  "betrayer_crypt_guardian_portrait": require("../assets/images/portraits/betrayer_crypt_guardian.png"),
  "kraken": require("../assets/images/minions/kraken.png"),
  "kraken_portrait": require("../assets/images/portraits/kraken.png"),
  "nightmare_lizard": require("../assets/images/minions/nightmare_lizard.png"),
  "nightmare_lizard_portrait": require("../assets/images/portraits/nightmare_lizard.png"),
  "malady": require("../assets/images/minions/malady.png"),
  "malady_portrait": require("../assets/images/portraits/malady.png"),
  "deathfog_chimera": require("../assets/images/minions/deathfog_chimera.png"),
  "deathfog_chimera_portrait": require("../assets/images/portraits/deathfog_chimera.png"),
  "adramahlihk": require("../assets/images/minions/adramahlihk.png"),
  "adramahlihk_portrait": require("../assets/images/portraits/adramahlihk.png")
} as const;
import {
  ImageBackground,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { IconButton, Button } from "react-native-paper";
import { useDispatch } from "react-redux";
import { EnemyState } from "../model/game";
import { Initiative } from "../model/initiative";
import { actions } from "../store/store";
import { getEnemy, getModifier } from "../db";
import { MAIN_FONT } from "../bundles/fonts";
import { ThemeProp } from "react-native-paper/lib/typescript/types";
import { MinionObj } from "./minion_obj";
const minion_marker = require("../assets/images/minion_marker.png");

interface Props {
  style?: StyleProp<ViewStyle>;
  initiative: Initiative;
  index: number;
  minion: MinionObj;
}

export default function MinionMarker(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { initiative, minion } = props;

  function HpDisplay(): JSX.Element {
    return (
      <View style={styles.hp}>
        <Button
          compact
          style={styles.button}
          theme={fiveButtonTheme}
          mode="contained"
          onPress={adjustHp(-5)}
          disabled={minion.currentHp <= 0}
        >
          <Text style={styles.fiveButton}>-5</Text>
        </Button>
        <IconButton
          iconColor="#ccc"
          containerColor="#00000044"
          icon="minus"
          mode="contained"
          onPress={adjustHp(-1)}
          disabled={minion.currentHp <= 0}
        />
        <View style={styles.spacer} />
        <IconButton
          iconColor="#ccc"
          containerColor="#00000044"
          icon="plus"
          mode="contained"
          onPress={adjustHp(1)}
          disabled={minion.maxHp <= minion.currentHp}
        />
        <Button
          compact
          style={styles.button}
          theme={fiveButtonTheme}
          mode="contained"
          onPress={adjustHp(5)}
          disabled={minion.maxHp <= minion.currentHp}
        >
          <Text style={styles.fiveButton}>+5</Text>
        </Button>
      </View>
    );
  }

  function adjustHp(amount: number): () => void {
    const newHp = Math.min(
      Math.max(0, minion.currentHp + amount),
      minion.maxHp
    );
    return () => {
      dispatch(actions.setHp({ initiative, index: props.index, hp: newHp }));
    };
  }

  return (
    <ImageBackground
      style={styles.heading}
      source={minion_marker}
      imageStyle={styles.headingBg}
    >
      <Text style={styles.letter}>{minion.letter}</Text>
      <HpDisplay />
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  letter: {
    fontFamily: MAIN_FONT,
    color: "white",
    fontSize: 24,
    textShadowColor: "black",
    textShadowRadius: 2,
    position: "absolute",
    top: 15,
  },

  heading: {
    alignItems: "center",
  },

  headingBg: {
    width: 50,
    maxWidth: 50,
    marginLeft: "50%",
    transform: [{ translateX: -25 }],
  },

  spacer: {
    width: 50,
  },

  button: {
    width: 40,
  },

  buttonContent: {
    margin: 0,
  },

  fiveButton: {
    fontSize: 20,
  },

  hp: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  hpButton: {
    color: "white",
  },

  currentHp: {
    fontSize: 20,
  },

  remove: {
    fontSize: 30,
    color: "red",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100%",
    width: "100%",
    textAlign: "center",
    padding: 10,
  },
});

const fiveButtonTheme: ThemeProp = {
  colors: { onPrimary: "#ccc", primary: "#00000044" },
};

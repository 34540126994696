import {
  MINION_STATUSES,
  STATUS_STRINGS,
  StatusEffect,
} from "../model/status_effect";
import {
  StyleSheet,
  View,
  Image,
  Pressable,
  StyleProp,
  ViewStyle,
} from "react-native";
import { Cooldown } from "../model/game";
import { Text } from "react-native-paper";
import { STATUS_IMAGES } from "../bundles/status_images";

const COOLDOWN_TURNS = [3, 2, 1];
const BOSS_COOLDOWN_TURNS = [5, 4, 3, 2, 1];

interface Props {
  style?: StyleProp<ViewStyle>;
  cooldowns: Cooldown[];
  isBoss: boolean;
  onChange: (status: StatusEffect, turn: number | undefined) => void;
}

export default function CooldownPicker(props: Props): JSX.Element {
  const { cooldowns } = props;

  function getCooldownsByTurn(
    cooldowns: Cooldown[]
  ): Map<number, Set<StatusEffect>> {
    const map = new Map<number, Set<StatusEffect>>([
      [1, new Set()],
      [2, new Set()],
      [3, new Set()],
      [4, new Set()],
      [5, new Set()],
    ]);

    cooldowns.forEach((c) => map.get(c.turns).add(c.status));

    return map;
  }

  const cooldownsByTurn = getCooldownsByTurn(cooldowns);
  const { onChange } = props;

  function StatusView(props: {
    status: StatusEffect;
    turn: number;
    disabled?: boolean;
  }): JSX.Element {
    const { status, disabled } = props;
    function statusPressed(): void {
      if (disabled) {
        // If we're currently disabled, add to current turn.
        onChange(status, props.turn);
      } else {
        // Otherwise remove entirely.
        onChange(status, undefined);
      }
    }

    return (
      <View
        style={[styles.statusView, disabled ? styles.disabled : styles.enabled]}
      >
        <Pressable onPress={statusPressed}>
          <Image
            style={[styles.statusImage]}
            source={STATUS_IMAGES.get(status)}
            resizeMode="contain"
          />
        </Pressable>
        <Text style={styles.statusName}>{STATUS_STRINGS.get(status)}</Text>
      </View>
    );
  }

  function CooldownRow(props: { turn: number }): JSX.Element {
    const { turn } = props;
    return (
      <View style={styles.cooldownRow}>
        <Text style={styles.turnLabel}>{props.turn}</Text>
        {MINION_STATUSES.map((s) => (
          <StatusView
            key={`${s}`}
            status={s}
            turn={turn}
            disabled={!cooldownsByTurn.get(turn).has(s)}
          />
        ))}
      </View>
    );
  }

  return (
    <View style={[props.style, styles.container]}>
      {(props.isBoss ? BOSS_COOLDOWN_TURNS : COOLDOWN_TURNS).map((turn) => (
        <CooldownRow key={turn} turn={turn} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    gap: 10,
    padding: 15,
    borderRadius: 5,
  },

  cooldownRow: {
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
  },

  turnLabel: { fontSize: 20 },

  toggles: {},

  statusView: {
    alignItems: "center",
    width: 60,
    padding: 4,
  },

  statusImage: { width: 50, height: 50 },

  statusName: {
    color: "black",
    fontSize: 14,
    textAlign: "center",
  },

  enabled: {
    backgroundColor: "#ff6",
  },

  disabled: {
    opacity: 0.5,
  },
});

function getCooldownIndex(cooldowns: Cooldown[]): Map<StatusEffect, number> {
  return new Map(cooldowns.map((c) => [c.status, c.turns]));
}

function adjustCooldowns(
  cooldowns: Cooldown[],
  status: StatusEffect,
  turn: number | undefined
): Cooldown[] {
  return cooldowns;
}

import { useState } from "react";
import { BaseEnemy, Enemy, isEnemy } from "../model/enemy";
import { getAllEnemies } from "../db";
import {
  Button,
  Checkbox,
  Text,
  List,
  Modal,
  Portal,
  Searchbar,
  SegmentedButtons,
} from "react-native-paper";
import { FlatList, StyleSheet, View, useWindowDimensions } from "react-native";
import { LETTERS, Letter } from "../model/game";
import { selectAvailableLetters } from "../store/selectors";
import { useSelector } from "react-redux";
import { globalStyles } from "./global_styles";
import {
  fromInitiativeLabel,
  getInitiativeLabel,
  Initiative,
} from "../model/initiative";
import { COLORS } from "./colors";
import { MAIN_FONT } from "../bundles/fonts";
import { hpForBoss, isBoss } from "../model/boss";

interface Props {
  visible: boolean;
  onCancel?: () => void;
  onAdd: (
    id: string,
    letter: Letter,
    initiative: Initiative,
    maxHp: number | undefined
  ) => void;
}

interface Filter {
  text?: string;
  veterans: VeteranState;
  preset: FilterPreset;
}

enum FilterPreset {
  ALL = "All",
  MAGISTERS = "Magisters",
  DEMONS = "Demons",
  BOSSES = "Bosses",
  VOIDWOKEN = "Voidwoken",
  UNDEAD = "Undead",
}

enum VeteranState {
  ALL = "Both",
  VETERANS = "Veterans",
  NON_VETERANS = "Non-veterans",
}

const VETERAN_STATES = [
  VeteranState.ALL,
  VeteranState.NON_VETERANS,
  VeteranState.VETERANS,
];

const PRESETS = [
  FilterPreset.ALL,
  FilterPreset.MAGISTERS,
  FilterPreset.VOIDWOKEN,
  FilterPreset.UNDEAD,
  FilterPreset.DEMONS,
  FilterPreset.BOSSES,
];

export default function EnemySelector(props: Props): JSX.Element {
  const [filter, setFilter] = useState<Filter>({
    veterans: VeteranState.ALL,
    preset: FilterPreset.ALL,
  });
  const [numPlayers, setNumPlayers] = useState(2);
  const items = getFilteredEnemies(filter);
  const availableLetters = useSelector(selectAvailableLetters);
  const dimension = useWindowDimensions();
  const [initiative, setInitiative] = useState(
    getInitiativeLabel(Initiative.FAST)
  );

  return (
    <Portal>
      <Modal
        dismissable={true}
        visible={props.visible}
        onDismiss={() => props.onCancel?.()}
        style={styles.modal}
      >
        <View
          testID="MyModalView"
          style={[styles.card, { maxHeight: dimension.height * 0.8 }]}
        >
          <Searchbar
            value={filter.text}
            onChangeText={(text) => setFilter({ ...filter, text })}
            autoFocus
          />
          <View style={styles.topFilters}>
            <SegmentedButtons
              style={globalStyles.flex1}
              value={initiative.toString()}
              onValueChange={(v) => setInitiative(v)}
              buttons={[
                {
                  label: "Fast",
                  value: getInitiativeLabel(Initiative.FAST),
                  checkedColor: COLORS.white,
                  icon: "rabbit",
                },
                {
                  label: "Slow",
                  value: getInitiativeLabel(Initiative.SLOW),
                  checkedColor: COLORS.white,
                  icon: "tortoise",
                },
              ]}
              theme={{ colors: { secondaryContainer: COLORS.darkRed } }}
            />
            <SegmentedButtons
              value={numPlayers.toString()}
              onValueChange={(v) => {
                setNumPlayers(parseInt(v));
              }}
              density="small"
              buttons={[
                { label: "2P", value: "2" },
                { label: "3P", value: "3" },
                { label: "4P", value: "4" },
              ]}
            />
          </View>
          <View style={styles.filters}>
            <SegmentedButtons
              style={globalStyles.flex1}
              value={filter.preset}
              onValueChange={(preset) => {
                setFilter({ ...filter, preset });
              }}
              buttons={PRESETS.map((p) => ({
                value: p,
                label: p,
                showSelectedCheck: true,
              }))}
            />
            <Button
              onPress={() =>
                setFilter({
                  ...filter,
                  veterans: getNextVeteranValue(filter.veterans),
                })
              }
            >
              {getVeteranLabel(filter.veterans)}
            </Button>
          </View>
          <FlatList
            data={items}
            renderItem={(item) => {
              return (
                <List.Item
                  style={styles.listItem}
                  key={item.item.id}
                  title={`${item.item.name} ${
                    isBoss(item.item)
                      ? `(${hpForBoss(numPlayers, item.item)} hp)`
                      : ""
                  }`}
                  titleStyle={styles.listItemTitle}
                  right={() => (
                    <View style={styles.letterButtons}>
                      {LETTERS.map((l) => (
                        <Button
                          style={styles.letterButton}
                          disabled={!availableLetters.has(l)}
                          key={l}
                          onPress={() =>
                            props.onAdd(
                              item.item.id,
                              l,
                              fromInitiativeLabel(initiative),
                              isBoss(item.item)
                                ? hpForBoss(numPlayers, item.item)
                                : undefined
                            )
                          }
                          mode="contained"
                        >
                          {l}
                        </Button>
                      ))}
                    </View>
                  )}
                />
              );
            }}
          />
          <Button onPress={() => props.onCancel?.()}>Done</Button>
        </View>
      </Modal>
    </Portal>
  );
}

const styles = StyleSheet.create({
  modal: {
    justifyContent: "flex-start",
    flex: 1,
    padding: "25%",
    paddingTop: 60,
    overflow: "hidden",
  },
  card: { backgroundColor: "white", gap: 4 },
  list: { flex: 1 },
  letters: {},
  letterButtons: { flexDirection: "row", gap: 4 },
  letterButton: { minWidth: 40 },
  listItem: { flex: 5 },
  listItemTitle: {
    color: "black",
  },

  topFilters: {
    flexDirection: "row",
    gap: 5,
    padding: 4,
  },

  filters: {
    flexDirection: "row",
    padding: 4,
  },
});

function getFilteredEnemies(filter: Filter): BaseEnemy[] {
  const textFilter = filter.text?.toLowerCase();
  const presetFilter = getPresetFilter(filter.preset);
  const enemies = getAllEnemies()
    .filter((e) => {
      switch (filter.veterans) {
        case VeteranState.ALL:
          return true;
        case VeteranState.VETERANS:
          return isEnemy(e) && e.veteran;
        case VeteranState.NON_VETERANS:
          return isBoss(e) || !(e as Enemy).veteran;
      }
    })
    .filter(presetFilter)
    .filter((e) => {
      return !textFilter || e.name.toLowerCase().indexOf(textFilter) !== -1;
    });
  enemies.sort((a, b) => a.name.localeCompare(b.name));
  return enemies;
}

function getPresetFilter(preset: FilterPreset): (enemy: BaseEnemy) => boolean {
  switch (preset) {
    case FilterPreset.ALL:
      return () => true;
    case FilterPreset.BOSSES:
      return isBoss;
    case FilterPreset.DEMONS:
      return (e) => e.name.toLowerCase().includes("demon");
    case FilterPreset.MAGISTERS:
      return (e) => e.name.toLowerCase().includes("magister");
    case FilterPreset.UNDEAD:
      return (e) => e.name.toLowerCase().includes("undead");
    case FilterPreset.VOIDWOKEN:
      return (e) => e.name.toLowerCase().includes("voidwoken");
  }
}

function getVeteranLabel(v: VeteranState): string {
  switch (v) {
    case VeteranState.ALL:
      return "All types";
    case VeteranState.VETERANS:
      return "Veterans only";
    case VeteranState.NON_VETERANS:
      return "Non-veterans only";
  }
}

function getNextVeteranValue(v: VeteranState): VeteranState {
  return VETERAN_STATES[
    (VETERAN_STATES.indexOf(v) + 1) % VETERAN_STATES.length
  ];
}
